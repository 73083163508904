<template>
  <div class="page-content">
    <img-text-section
      :bg-img-src="firstImgSrc"
      :section-title="firstTitle"
      :section-text="textList"
      :section-btn="sectionBtn"
      @Register="Register"
    >
    </img-text-section>

    <img-section
      :section-title="secondTitle"
      :img-src="secondImgSrc"
    ></img-section>

    <icon-list-section
      :section-title="thirdTitle"
      :icon-list="iconList"
      :con-center="contentCenter"
    >
    </icon-list-section>
  </div>
</template>

<script>
import ImgTextSection from "@c/ImgTextSection";
import ImgSection from "@c/ImgSection";
import IconListSection from "@c/IconListSection";

export default {
  name: "data-collection-plan",
  components: {
    ImgTextSection,
    ImgSection,
    IconListSection,
  },
  data() {
    return {
      firstTitle: "全端数据采集，一应俱全",
      textList: ["深度洞察用户行为，不遗漏关键数据。"],
      firstImgSrc: require("../assets/images/bg_3.png"),
      sectionBtn: {
        text: "体验Demo",
        url: "#",
      },
      secondTitle: "多种采集方案",
      secondImgSrc: require("../assets/images/bg_4.png"),
      thirdTitle: "灵活采集方法",
      iconList: [
        {
          title: "全埋点",
          text: "支持多种SDK，基础事件自动采集，部署简单快捷",
          imgSrc: require("../assets/images/icon_11.png"),
        },
        {
          title: "自定义埋点",
          text: "根据业务个性化定制内容，精确定位关键指标",
          imgSrc: require("../assets/images/icon_12.png"),
        },
      ],
      contentCenter: true,
    };
  },
  methods: {
    Register(v) {
      this.$emit("priceRegister", v);
    },
    logOut() {
      this.$parent.loginOut();
    },
  },
};
</script>

<style scoped>
</style>
